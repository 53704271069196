// ** React Imports
import { memo, useMemo, useState, useContext, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";

// ** Redux & Store & Actions
import { useDispatch, useSelector } from "react-redux";
import { getLanding } from "store/modules/mainPages";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import withHydrationOnDemand from "react-hydration-on-demand";
import loadable from "@loadable/component";

// ** Custom Components
import Block from "components/layout/Block";
import Section from "components/layout/Section";
import SectionBackground from "./components/SectionBackground";
import LogoLanding from "./components/LogoLanding";
import ScrollToTop from "components/global/ScrollToTop";
import Paragraph from "components/typography/Paragraph";
import Text from "components/typography/Text";
import Heading from "components/typography/Heading";

const ButtonFloating = loadable(() =>
	import("components/layout/Button/ButtonFloating")
);
const ButtonFloatingWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: ButtonFloating.load,
})(ButtonFloating);
const Footer = loadable(() => import("components/global/Footer"));
const FooterWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: Footer.load,
})(Footer);

// ** Landing Components
import AuthPanel from "./components/AuthPanel";

const Welcome = loadable(() => import("./components/Welcome"));
const WelcomeWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: Welcome.load,
})(Welcome);

const WelcomeSlider = loadable(() => import("./components/WelcomeSlider"), {
	ssr: false,
});
const WelcomeSliderWithHydrationOnDemand = withHydrationOnDemand({
	on: ["idle"],
	onBefore: WelcomeSlider.load,
})(WelcomeSlider);

const StatisticsSection = loadable(() =>
	import("./components/StatisticsSection")
);
const StatisticsSectionWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: StatisticsSection.load,
})(StatisticsSection);

const FeaturesAndMenuSection = loadable(() =>
	import("./components/FeaturesAndMenuSection")
);
const FeaturesAndMenuSectionWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: FeaturesAndMenuSection.load,
})(FeaturesAndMenuSection);

const AboutUsSection = loadable(() => import("./components/AboutUsSection"));
const AboutUsSectionWithHydrationOnDemand = withHydrationOnDemand({
	on: ["visible"],
	onBefore: AboutUsSection.load,
})(AboutUsSection);

// ** Custom Hooks
import useOpenGraphMetaTags from "hooks/useOpenGraphMetaTags";
import useScrollPosition from "hooks/useScrollPosition";
import useDetectKeyboardOpen from "hooks/useDetectKeyboardOpen";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";
import AuthContext from "contexts/authContext";

// ** Styled Components
import { StyledSectionContent, StyledSectionWelcome } from "./styles";
import { StyledCol as Col } from "styles/components/Col.styled";

// #####################################################

const Landing = () => {
	const { isPhone, isTablet } = useContext(deviceTypeContext);

	const { hash } = useLocation();
	const history = useHistory();

	const dispatch = useDispatch();
	useScrollPosition();

	const isAuth = useContext(AuthContext);

	const language = useSelector((state) => state.global.language);

	const landingDataLoaded = useSelector(
		(state) => state.mainPages.landing.loaded
	);

	const isRegister = hash === "#register";
	const isRecover = hash === "#recover";

	const [activeTab, setActiveTab] = useState(
		isRegister ? "register" : isRecover ? "recover" : "login"
	);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 400) {
				setShowFloatingRegisterForFreeButton(true);
			} else {
				setShowFloatingRegisterForFreeButton(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const [
		showFloatingRegisterForFreeButton,
		setShowFloatingRegisterForFreeButton,
	] = useState(false);

	const { t } = useTranslation(["landing"]);

	const { isKeyboardOpen } = useDetectKeyboardOpen();

	const customTitle = useMemo(() => {
		const title = t("landing:title");
		return `${title}`;
	}, [language]);

	// ** Open Graph Meta Tags
	const ogMetaTags = useOpenGraphMetaTags({
		customTitle,
	});

	useEffect(() => {
		if (!landingDataLoaded) {
			dispatch(getLanding({ language }));
		}
	}, [language, landingDataLoaded, isAuth]);

	useEffect(() => {
		if (history?.location?.state === "register") {
			setActiveTab("register");
		}
	}, [history?.location?.state]);

	// #####################################################

	return (
		<>
			{/* Sekcja HEAD */}
			<Helmet title={customTitle} titleTemplate="%s">
				{ogMetaTags}
				<link rel="canonical" href={`https://www.datezone.com`} />
			</Helmet>

			<ScrollToTop />

			<div className="landingAlwaysLight">
				{/* Główna zawartość strony */}
				<Section background={<SectionBackground />}>
					<Block flex flexWrap>
						<Col>
							<StyledSectionContent
								isKeyboardOpen={isKeyboardOpen}
							>
								<Block
									px={isPhone ? 4 : 0}
									pt={isPhone ? 4 : 0}
								>
									<Link to="/">
										<LogoLanding />
									</Link>
								</Block>
								<Block flex flexWrap>
									{isPhone || isTablet ? (
										<Col>
											<Block pt={12} pb={4}>
												<AuthPanel
													activeTab={activeTab}
													setActiveTab={setActiveTab}
												/>
											</Block>
										</Col>
									) : (
										<>
											<Col hiddenLgDown lg={7}>
												{!isPhone && (
													<WelcomeWithHydrationOnDemand />
												)}
											</Col>
											<Col md={12} lg={5}>
												<Block pt={12}>
													<AuthPanel
														activeTab={activeTab}
														setActiveTab={
															setActiveTab
														}
													/>
												</Block>
											</Col>
										</>
									)}
								</Block>
							</StyledSectionContent>
						</Col>
					</Block>
				</Section>

				{(isPhone || isTablet) && (
					<StyledSectionWelcome>
						<Block fullWidth justifyCenter alignCenter>
							<Block pt={2} mb={6}>
								<Heading bold type="h1" size="1.75rem">
									{t("landing:header")}
								</Heading>
								<Paragraph size="1.25rem" bold>
									<Text>
										{t("landing:subheader")}{" "}
										{t("landing:descCta")}
									</Text>
								</Paragraph>
								<Paragraph size="1.25rem" bold>
									<Text>{t("landing:desc")}</Text>
								</Paragraph>
							</Block>
							<WelcomeSliderWithHydrationOnDemand />
						</Block>
					</StyledSectionWelcome>
				)}
			</div>
			<StatisticsSectionWithHydrationOnDemand />

			<FeaturesAndMenuSectionWithHydrationOnDemand />

			{language === "pl" && <AboutUsSectionWithHydrationOnDemand />}

			<FooterWithHydrationOnDemand id="footer" />

			{showFloatingRegisterForFreeButton && (
				<ButtonFloatingWithHydrationOnDemand
					onClick={() => {
						setActiveTab("register");
						window.scrollTo(0, 0);
					}}
				>
					{t("landing:registerForFree")}
				</ButtonFloatingWithHydrationOnDemand>
			)}
		</>
	);
};

// #####################################################

export default memo(Landing);
